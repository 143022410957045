.defaultButton,
.secondaryButton,
.secondaryRedButton,
.primaryButton {
	min-height: 44px;
	display: flex;
	padding: 12px 12px 12px 12px;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
}

.defaultButton {
	background: rgba(102, 9, 175, 1);
}

.defaultButton:hover,
.defaultButton:focus {
	background: rgba(131, 15, 222, 1);
}

.defaultButton:active {
	background: rgba(80, 9, 136, 1);
}

.primaryButton {
	background: #e20073;
	border: none;
}

.primaryButton:hover {
	background: #f92793;
}

.primaryButton:active {
	background: #c40266;
}

.primaryButton:focus {
	background: #ed3996;
}

.secondaryButton {
	border: 1px solid #999999;
}

.secondaryButton:hover {
	background: #fff;
}

.secondaryButton:active {
	background: #fff;
}

.secondaryButton:focus {
	background: #fff;
}

.secondaryRedButton {
	background: #fff;
	color: #e02189;
	border: 1px solid #e02189;
}

.secondaryRedButton:focus,
.secondaryRedButton:active {
	color: #e02189;
	border: 1px solid #e02189;
}

.secondaryRedButton:hover {
	color: #e02189;
	border: 1px solid #e02189;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.129);
}
