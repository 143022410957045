.error {
    font-size: 48px;
    font-weight: 900;
}

.info {
    font-size: 18px;
    text-align: center;
}

.studioLogo a {
    text-decoration: none;
}

@media only screen and (max-width: 570px) {
    .image {
        width: 330px;
        height: 200px;
    }
    .info {
        font-size: 14px;
        max-width: 300px;
    }
    .error {
        font-size: 28px;
    }
    .wizard {
        width: 280px;
    }
}